const textContent = {
  stats: {
    percentage: [
      [4, 8, 12, 20, 35],
      [8, 15, 25, 35, 50],
    ],
    days: ['0 days', '14 days', '120 days', '240 days', '360 days'],
  },
};

export default textContent;
