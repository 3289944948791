export const LightSvg = (
  <svg
    width='11'
    height='14'
    viewBox='0 0 11 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.3261 7.7747C7.89844 9.76769 5.43522 11.7169 2.98567 13.6826C2.6166 13.9779 2.23932 14.1501 1.81284 13.822C1.34535 13.4639 1.47657 13.0429 1.69528 12.6027C2.39789 11.2002 3.09229 9.7923 3.78396 8.38435C3.87144 8.20665 3.95073 8.02075 4.06282 7.76923C2.95013 7.76923 1.91946 7.7583 0.891525 7.7747C0.451372 7.78017 0.041291 7.65714 0.0030168 7.20879C-0.0243219 6.89986 0.136976 6.45424 0.372089 6.26287C2.83257 4.24801 5.32586 2.27142 7.81095 0.281159C8.16636 -0.00589793 8.53543 -0.126188 8.93457 0.177271C9.36926 0.50807 9.29271 0.912683 9.07947 1.33917C8.36866 2.76351 7.66332 4.18786 6.96345 5.61494C6.87597 5.79538 6.79669 5.97855 6.6846 6.23006C7.77541 6.23006 8.78421 6.241 9.79301 6.22459C10.2496 6.21639 10.7034 6.30388 10.7444 6.79051C10.769 7.1131 10.5776 7.56966 10.3261 7.7747Z'
      fill='#6457FF'
    />
  </svg>
);
