export const ArrowSvg = (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.73594 5.99988C8.73594 5.82392 8.67025 5.64798 8.53914 5.51383L4.41131 1.29223C4.14873 1.02368 3.723 1.02368 3.46053 1.29223C3.19805 1.56067 3.19805 1.99599 3.46053 2.26456L7.11306 5.99988L3.46066 9.73523C3.19818 10.0038 3.19818 10.4391 3.46066 10.7075C3.72313 10.9761 4.14886 10.9761 4.41144 10.7075L8.53927 6.48594C8.6704 6.35172 8.73594 6.17578 8.73594 5.99988Z'
      fill='white'
    />
  </svg>
);
