export const cards = [
  {
    title: 'High Yields',
    description:
      'Earn up to 150% APY, enticing potential investors with the prospect of substantial returns.',
  },
  {
    title: 'Expertise',
    description:
      'Leverage the skills of our professional traders and analysts to generate profits and ensure a stable yield.',
  },
  {
    title: 'Flexibility',
    description:
      'Tailor your investment experience with our innovative Investment Pools, catering to diverse risk appetites.',
  },
  {
    title: 'Passive Income',
    description:
      'Secure a steady stream of monthly USDT dividends, powered by 30% of treasury profits generated from trading and investment activities.',
  },
  {
    title: 'Stability',
    description:
      'Experience a more stable ecosystem through yield vesting when unstaking previously staked DHF or DHF liquidity.',
  },
  {
    title: 'Transparency',
    description:
      'Engage in an open, community-driven project, with opportunities for involvement and staying informed about progress.',
  },
];
