const ArrowSvg = (
  <svg
    width='9'
    height='6'
    viewBox='0 0 9 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.8916 5.50671C4.69142 5.75887 4.30858 5.75887 4.10839 5.50671L0.38058 0.810878C0.120451 0.483199 0.353806 1.10377e-07 0.772186 1.46953e-07L8.22781 7.98745e-07C8.64619 8.35321e-07 8.87955 0.4832 8.61942 0.810879L4.8916 5.50671Z'
      fill='white'
    />
  </svg>
);

export default ArrowSvg;
